export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.updateQuantity()

    return this
  }

  updateQuantity () {
    const quantityStep = this.configurator.steps.find(step => step.type === 'quantity')
    if (quantityStep) {
      this.configurator.quantity = quantityStep.value
    }
  }
}
