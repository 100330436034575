import _ from 'lodash'
export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    const result = this.fetchExampleImages()
    return result
  }

  fetchExampleImages () {
    let images = []
    const steps = this.configurator.exampleImages
    let option; let stepId; let options; let optionId
    for (stepId in steps) {
      options = steps[stepId]
      for (optionId in options) {
        option = this.configurator.getOption({ stepId: stepId, optionId: optionId })
        if (option) {
          if (option.selected === true) {
            images = [...images, ...options[optionId]]
          }
        }
      }
    }
    return _.uniq(images)
  }
}
