import * as types from '../mutation-types'
import axios from 'axios'
// state
export const state = {
  menu: null,
  statusMenu: false
}
// getters
export const getters = {
  menu: state => {
    const arr = state.menu
    if (arr) {
      const arrMap = new Map(arr.map(item => [item.id, item]))
      const tree = []
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (item.parent_id !== 0) {
          const parentItem = arrMap.get(item.parent_id)
          if (parentItem) {
            const { children } = parentItem
            if (children) {
              parentItem.children.push(item)
            } else {
              parentItem.children = [item]
            }
          }
        } else {
          tree.push(item)
        }
      }
      return tree
    }
  },
  statusMenu: state => state.statusMenu
}
// mutations
export const mutations = {
  [types.SET_MENU] (state, { menu }) {
    state.menu = menu
  },
  [types.SET_STATUS_MENU] (state, status) {
    state.statusMenu = status
  }
}
// actions
export const actions = {
  async setMenu ({ commit }) {
    const apiKey = btoa(process.env.VUE_APP_API_KEY)
    const apiUrl = process.env.VUE_APP_API_URL
    const { data } = await axios.post(apiUrl + 'navigation', { re: apiKey })
    commit(types.SET_MENU, { menu: data })
  }
}
