<template>
<Popper v-if="tooltip.text || tooltip.image" hover placement="right" closeDelay="0" class="text-end">
    <slot />
    <template #content>
      <p v-if="tooltip.text" :class="{ 'mb-0': !tooltip.image, 'mb-2': tooltip.image }" v-html="tooltip.text"></p>
      <img class="img-fluid" :src="tooltip.image" v-if="tooltip.image && tooltip.type === 'blind'">
      <img class="img-fluid" v-else-if="tooltip.image" :src="$filters.image(tooltip.image, '350x350')">
    </template>
</Popper>
</template>
<script>
import Popper from 'vue3-popper'
export default {
  name: 'Tooltip',
  props: {
    tooltip: Object,
    styleObject: {
      type: Object,
      default: null
    }
  },
  components: {
    Popper
  }
}
</script>
<style>
  :root {
    --popper-theme-background-color: #fafafa;
    --popper-theme-background-color-hover: #fff;
    --popper-theme-text-color: #212121;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 5px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
  .popper {
    width: 500px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
</style>
