/* eslint-disable no-useless-escape */
import Slugify from 'slugify'
export const config = (name, definition) => {
  return [window.location.origin, 'show', Slugify(name, { lower: true }), definition].join('/')
}
export const definition = () => {
  if (location.href.match(/\/show\/[^\/]+\/([^\/]+)/gmi)) {
    return location.href.split('/').pop()
  }

  return null
}
