<template>
<nav class="header__nav" :class="[burger ? 'open-side' : '']">
  <button @click="closeMenu" type="button" class="btn-close header__nav-close"></button>
  <ul v-if="navigation" class="header__menu">
    <span v-for="(item, index) in navigation" :key="index">
      <li v-if="item.children" class="dropdown">
        <a href="#" @click="showDrop(index)" class="header__menu-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" :id="`dropdownMenuButton${index}`" role="button">{{ item.name }}</a>
        <div v-if="item.children && item.children[0].image" class="dropdown-menu p-4" :style="'width: ' + wSize + 'px'" :aria-labelledby="`dropdownMenuButton${index}`" :id="`dropdownMenu${index}`">
          <div class="row">
            <div class="col text-center" v-for="child in item.children" :key="child.id">
              <p class="fs-4" :href="child.url" :target="child.target">{{ child.name }}</p>
              <small>{{ child.description }}</small>
              <img :src="$filters.image(child.image)" class="img-fluid"/>
              <a class="btn btn-outline-warning" :href="child.url" :target="child.target">Jetzt konfigurieren</a>
            </div>
          </div>
        </div>
        <ul v-else class="dropdown-menu" :id="`dropdownMenu${index}`">
          <li v-for="child in item.children" :key="child.id">
            <a class="dropdown-item" :href="child.url" :target="child.target">{{ child.name }}</a>
          </li>
        </ul>
      </li>
      <li v-else>
        <a :href="item.url" :target="item.target" class="header__menu-link">{{ item.name }}</a>
      </li>
    </span>
  </ul>
</nav>
</template>
<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const width = ref(window.innerWidth)
    const status = ref(false)
    onMounted(() => {
      store.dispatch('menu/setMenu')
      // checking resize
      window.onresize = () => {
        width.value = window.innerWidth
      }
    })
    const wSize = computed(() => {
      if (width.value > 992) {
        return 700
      } else {
        return 255
      }
    })
    // computed
    const burger = computed(() => {
      return store.getters['menu/statusMenu']
    })
    const navigation = computed(() => {
      return store.getters['menu/menu']
    })
    // actions
    function closeMenu () {
      store.commit('menu/SET_STATUS_MENU', false)
    }
    function showDrop (index) {
      const button = document.getElementById('dropdownMenuButton' + index)
      const div = document.getElementById('dropdownMenu' + index)
      status.value = !status.value
      if (status.value) {
        button.classList.add('show')
        div.classList.add('show')
      } else {
        button.classList.remove('show')
        div.classList.remove('show')
      }
    }
    return {
      wSize,
      burger,
      navigation,
      closeMenu,
      showDrop
    }
  }
}
</script>
