export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.configurator.steps.filter(s => s.type === 'conditional' ||
    s.type === 'montage_step' ||
    s.type === 'glass' ||
    s.type === 'color_roof' ||
    s.type === 'color_screen').forEach(step => {
      step.options.forEach(option => {
        if ((option.show_for || []).length > 0) {
          if (!step.options.find(o => option.show_for.indexOf(o.id) > -1 && o.selected)) {
            option.disabled = true
            option.dname = 'children'

            return
          }
        } else {
          option.children = []
        }

        option.disabled = false
      })
    })

    return this
  }
}
