export default class {
  constructor (configurator) {
    this.configurator = configurator
    this.totalDiscount = 0
  }

  process () {
    this.totalDiscount = 0
    this.updateDiscounts()

    return this
  }

  getColorDiscount () {
    const step = this.configurator.steps.find(s => s.type === 'color')
    let colorDiscount = 0
    const colors = step.colors
    const color = colors.find(c => c.selected)
    if (color && color.discount > 0) {
      colorDiscount = Number.parseInt(color.discount)
    }
    return colorDiscount
  }

  updateDiscounts () {
    this.configurator.steps.forEach((step) => {
      // if (step.options && !step.price_all) {
      if (step.options && step.model_step) {
        step.options.forEach((option) => {
          this.updateOption(option)
        })
      } else if (step.options) {
        step.options.forEach((option) => {
          if (option.discount > 0) {
            const discount = option.price.total * (Number.parseInt(option.discount) / 100)
            option.price.discounted = option.price.total - discount
            // option.price.self = option.price.total - discount;
            this.totalDiscount += discount
          }
        })
      }
    })

    // console.log('total discount: ' + this.totalDiscount); // sum discount total
    this.configurator.price.old_price = Math.ceil(this.configurator.price.total)
    this.configurator.price.total -= this.totalDiscount
    const configDiscount = this.configurator.discount
    const configTotalPrice = this.configurator.price.total
    this.configurator.price.discounted = Math.ceil(configTotalPrice - (configTotalPrice / 100 * configDiscount))
  }

  updateOption (option) {
    if (option.price && option.discount) {
      const discount = option.price.total * ((Number.parseInt(option.discount) + this.getColorDiscount()) / 100)
      option.price.discounted = option.price.total - discount
      option.price.self = option.price.total - discount
      this.totalDiscount += discount
    }
  }
}
