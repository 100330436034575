import axios from 'axios'
import Serializer from '@/lib/orderSerializer'
import * as types from '../mutation-types'

function updatePrices ({ state, commit, getters }) {
  /* eslint-disable */
  let prices = state.items.reduce((memo, item) => {
    return {
      total: memo.total + item.price.total,
      discounted: memo.discounted + item.price.discounted
    }
  }, {
    total: 0,
    discounted: 0
  })
  let deliveryPrice = state.multiple ? getters.country.price : getters.country.single_pr_price
  if (getters.pickup || getters.country.free_limit > -1 && getters.country.free_limit < prices.doscounted) {
    deliveryPrice = 0
  }
  prices.delivery = deliveryPrice
  const paymentAmountType = getters.paymentAmountType
  if (paymentAmountType && paymentAmountType.discount > 0) {
    if (getters.paymentType.label === 'Paypal') {
      prices.discounted = prices.discounted / 100 * (100 - 0)
    } else {
      prices.discounted = prices.discounted / 100 * (100 - paymentAmountType.discount)
    }
  }
  // if cart is empty, update delivery price
  if (state.items.length === 0) {
    prices.delivery = 0
  }
  if (prices.total === 0) {
    commit(types.SET_MONTAGE_STATUS, false)
    commit(types.SET_MONTAGE_COST, 0)
  }
  commit(types.SET_PRICES, prices)
}
export const state = {
    orderId: null,
    checkoutId: null,
    orderSending: false,
    discount: 20, // discount percent for 'anzahlung
    items: [],
    countries: null,
    country: 'Deutschland',
    pickup: false,
    price: {
      discounted: 0,
      total: 0,
      delivery: 0
    },
    customer: {
      additional: '',
      differentDeliveryAddress: false,
      address: {
        name: '',
        company: '',
        phone: '',
        email: '',
        street: '',
        zip: '',
        city: ''
      },
      deliveryAddress: {
        name: '',
        company: '',
        phone: '',
        email: '',
        street: '',
        zip: '',
        city: '',
      }
    },
    paymentAmount: {
      selected: 1,
      types: [
        {
          id: 1,
          name: 'Anzahlung 20%',
          label: 'Sie zahlen 20% an und den Restbetrag bezahlen Sie erst bei der Lieferung.',
          sublabel: 'exkl. eventueller Montagekosten',
          discount: 0,
          prepayment: 20,
          customer_discount: null
        },
        {
          id: 2,
          name: 'Ich zahle den gesamten Betrag (Skonto -1%)',
          label: 'Bei dieser Zahlungsart ist die Zahlung per PayPal nicht möglich.',
          sublabel: '2% Ersparnis auf die gesamte Rechnung',
          discount: 2,
          prepayment: 0,
          customer_discount: 'Bei Skontozahlung sparen Sie'
        },
      ],
    },
    payment: {
      selected: 1,
      types: [
        {
          id: 1,
          label: 'Überweisung',
          icon: '',
          disabled: false,
          description: 'Bitte überweisen Sie den Betrag auf unser Bankkonto. Die Kontoangaben erhalten Sie per E-Mail nach Abschluss der Bestellung'
        },
        {
          id: 2,
          label:'Paypal',
          icon: '/img/paypal.png',
          disabled: false,
          description: 'Zahlen Sie sicher und bequem mit Ihrem PayPal-Konto. PayPal-Zahlungen akzeptieren wir nur für Anzahlung.'
        },
        //{id: 3, label: 'Sofort', icon: 'https://cdn.klarna.com/1.0/shared/image/generic/badge/de_de/pay_now/descriptive/pink.svg', description: 'Customers who want to pay in full at checkout can do it quickly and securely with Klarnas Pay now-option - using credit/debit card or Direct bank transfer: Klarna Direkt, Sofort, SEPA Direct Debit.'},
      ],
      guarantee: false,
    },
    url: null,
    montageCost: null,
    montageStatus: false,
    montageBonus: 0,
    promocode: {
      status: false,
      discount: 0,
      total: 0,
      id: '',
      code: ''
    },
    multiple: true
}
// getters
export const getters = {
  items: state => state.items,
  price: state => state.price,
  countries: state => state.countries,
  country: state => state.countries.find(c => c.name === state.country),
  pickup: state => state.pickup,
  customerAddress: state => state.customer.address,
  deliveryAddress: state => state.customer.deliveryAddress,
  differentDeliveryAddress: state => state.customer.differentDeliveryAddress,
  customerAdditional: state => state.customer.additional,
  customer: state => state.customer,

  payment: state => state.payment,
  paymentType: state => state.payment.types.find(p => p.id === state.payment.selected),

  paymentAmount: state => state.paymentAmount,
  paymentAmountType: state => state.paymentAmount.types.find(t => t.id === state.paymentAmount.selected),

  orderId: state => state.orderId,
  checkoutId: state => state.checkoutId,

  orderSending: state => state.orderSending,
  url: state => state.url,
  montageCost: state => state.montageCost,
  montageStatus: state => state.montageStatus,
  montageBonus: state => state.montageBonus,
  multiple: state => state.multiple,
  promocode: state => state.promocode,
}
// actions
export const actions = {
  addItem (context, item) {
  	context.commit(types.CHANGE_TYPE_OF_CART, true)
  	context.commit(types.ADD_ITEM_TO_CART, item)
  	updatePrices(context)
  },
  addProduct (context, item) {
  	context.commit(types.CHANGE_TYPE_OF_CART, false)
  	context.commit(types.ADD_ITEM_TO_CART, item)
  	updatePrices(context)
  },
  removeItem (context, id) {
    context.getters.items.splice(context.getters.items.findIndex(el => {
      return el.id === id
    }), 1)
    // update montage
    context.commit(types.SET_MONTAGE_BONUS, 0)
    context.commit(types.SET_ITEMS, context.getters.items)
    updatePrices(context)
  },
  changeQty (context, id) {
  	const option = context.state.items.find(o => o.id === id)
  	let p = 0
    // const d = 0
    const oldPrice = Number.parseFloat(option.price.old_price)
    const qty = Number.parseInt(option.quantity)
    let discount = 0
    let totalDiscount = 0
    const disc = Number.parseInt(option.discount)
    p = oldPrice * qty
    discount = disc / 100 * oldPrice
    totalDiscount = (oldPrice * qty) - discount
    option.price.total = p
    option.price.discounted = totalDiscount
    updatePrices(context)
  },
  selectDelivery (context, countryName) {
  	context.commit(types.SET_DELIVERY_COUNTRY, countryName)
  	updatePrices(context)
  },
  selectPaymentAmount (context, id) {
  	context.commit(types.SET_SELECTED_PAYMENT_AMOUNT, id)
  	updatePrices(context)
  },
  updatePickup (context, pickup) {
  	context.commit(types.SET_PICKUP, pickup)
  	updatePrices(context)
  },
  changePaymentType (context) {
  	updatePrices(context)
  },
  changePayment (context, val) {
    context.commit(types.SET_SELECTED_PAYMENT, val)
    updatePrices(context)
  },
  async order (context) {
    context.commit(types.SET_ORDER_ID, null)
    context.commit(types.SET_CHECKOUT_ID, null)
  	context.commit(types.SET_ORDER_SENDING, true)
  	const serializer = new Serializer(context)
    const apiUrl = process.env.VUE_APP_API_URL
  	await axios.post(apiUrl + 'checkout', { order: serializer.serialize() }).then(res => {
      if (res.data && res.data.orderId) {
        context.commit(types.SET_ORDER_ID, res.data.orderId)
        context.commit(types.SET_CHECKOUT_ID, res.data.id)
        context.commit(types.SET_ORDER_SENDING, false)
        context.commit(types.TRUNCATE_CART)
        context.commit(types.SET_PROMOCODE, { status: false,discount: 0,total: 0,id: '',code: '' })
      }
    })
  },

  async setDeliveryCountries (context) {
    const apiKey = btoa(process.env.VUE_APP_API_KEY)
    const apiUrl = process.env.VUE_APP_API_URL
    await axios.post(apiUrl + 'configs/all', { re: apiKey, name: 'deliveries' }).then(resp => {
      if (resp.data !== null) {
        const data = JSON.parse(resp.data.value)
        context.commit(types.SET_COUNTRIES, data)
      }
    })
  },
  setPromocode (context, promocode) {
    context.commit(types.SET_PROMOCODE, promocode)
  },
  clearPromocode (context) {
    context.commit(types.SET_PROMOCODE, { status: false, discount: 0, total: 0, id: '', code: '' })
  }
}
// mutations
export const mutations = {
  [types.RESET] (state) {
    state.items = []
    state.price = {
      discounted: 0,
      total: 0,
      delivery: 0
    }
  },
  [types.ADD_ITEM_TO_CART] (state, item) {
  	state.items.push(item)
  },
  [types.SET_ITEMS] (state, items) {
  	state.items = items
  },
  [types.SET_DELIVERY_COUNTRY] (state, country) {
  	state.country = country
  },
  [types.SET_COUNTRIES] (state, countries) {
    state.countries = countries
  },
  [types.SET_PRICES] (state, prices) {
  	state.price = prices
  },
  [types.SET_PICKUP] (state, pickup) {
  	state.pickup = pickup
  },
  [types.SET_SELECTED_PAYMENT_AMOUNT] (state, id) {
  	state.paymentAmount.selected = id
  },
  [types.TOGGLE_DIFFERENT_ADDRESS] (state) {
  	state.customer.differentDeliveryAddress = !state.customer.differentDeliveryAddress
  },
  [types.SET_ORDER_SENDING] (state, status) {
  	state.orderSending = status
  },
  [types.SET_ORDER_ID] (state, id) {
  	state.orderId = id
  },
  [types.SET_CHECKOUT_ID] (state, id) {
  	state.checkoutId = id
  },
  [types.TRUNCATE_CART] (state) {
  	state.items = []
  },
  [types.SET_URL] (state, url) {
  	state.url = url
  },
  [types.UPDATE_URL] (state) {
  	state.url = null
  },
  [types.SET_MONTAGE_COST] (state, cost) {
  	state.montageCost = cost
  },
  [types.SET_MONTAGE_STATUS] (state, status) {
  	state.montageStatus = status
  },
  [types.CHANGE_TYPE_OF_CART] (state, status) {
  	state.multiple = status
  },
  [types.SET_MONTAGE_BONUS] (state, bonus) {
    state.montageBonus = bonus
  },
  [types.SET_PROMOCODE] (state, promocode) {
    state.promocode = promocode
  },
  [types.SET_SELECTED_PAYMENT] (state, value) {
    const item = state.payment.types.find(t => t.id === value.item)
    item.disabled = value.disabled
    state.payment.selected = 1
  }
}