export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    if (this.configurator.steps.find(s => s.type === 'dimension_glass')) {
      this.updateDimensionPrices()
    }
    if (this.configurator.steps.find(s => s.type === 'dimension_awnings')) {
      this.updateDimensionAwningsPrices()
    }
    this.updateColorPrices()
    this.updatePrices()
    // this.updateStandartDimPrice();
    return this
  }

  updatePrices () {
    const dim = this.configurator.steps.find(s => s.type === 'dimension_glass')
    this.configurator.steps.forEach((step) => {
      if (step.options && step.type !== 'glass') {
        step.options.forEach((option) => {
          this.updateOptionPrice(option)
        })
      } else if (step.type === 'glass') {
        step.options.forEach((option) => {
          this.updateGlassOptionPrice(option, dim)
        })
      }
      this.updateStepPrice(step)
    })

    const total = this.configurator.steps.reduce(function (price, step) {
      return price + (step.price ? step.price.total || 0 : 0)
    }, 0)
    this.configurator.price.total = total * (this.configurator.quantity || 1)
  }

  updateColorPrices () {
    const self = this

    this.configurator.steps.filter((step) => step.type === 'color').forEach((step) => {
      if (!step.visible) {
        step.price.total = 0

        return
      }

      const selectedColor = step.colors.find(color => color.selected)
      const totalRelatedPrice = self.configurator.steps.filter((filterStep) => step.price_on_steps.indexOf(filterStep.id) !== -1)
        .reduce((totalPrice, step) => totalPrice + step.price.total, 0)

      step.price.total = (totalRelatedPrice / 100) * (selectedColor.special ? Number.parseInt(step.price_special) : step.price)
    })
  }

  updateDimensionPrices () {
    const step = this.configurator.steps.find(s => s.model_step)
    const option = step.options.find(o => o.selected)
    const markup = (step && typeof step.markup !== 'undefined') ? step.markup / 100 : 0

    if (option && typeof option.special_dim !== 'undefined') {
      // const standart = option.standart_dim.find(o => o.selected)
      const special = option.special_dim.find(s => s.selected)
      if (typeof special !== 'undefined') {
        const tax = 19 / 100
        const heightTotal = this.countHeightTotal(option) // get height price
        const heightAdditionalTotal = this.countHeightAdditionalTotal(option) // get height additional price
        const markupPercent = (option && option.markup) ? special.price * (Number.parseInt(option.markup) / 100) : special.price * markup
        const priceMarkup = special.price + markupPercent
        const priceTax = priceMarkup * tax
        const total = priceMarkup + priceTax + (heightTotal + heightAdditionalTotal)
        // let price_discount = total * discounted;
        option.price.self = total
        option.price.total = step.visible ? option.price.self : 0
      }
    }
  }

  countHeightTotal (option) {
    const heightPrice = Number.parseFloat(option.dimension.height.price_per)
    const heightPer = Number.parseInt(option.dimension.height.dim_per)
    const heightValue = Number.parseInt(option.dimension.height.value)
    let heightTotal = 0
    if (heightValue > 100) {
      const parts = heightValue - 100
      const cm = parts / heightPer
      const val = (cm + 1) * heightPrice
      heightTotal = val
    } else {
      heightTotal = heightPrice
    }
    return heightTotal
  }

  countHeightAdditionalTotal (option) {
    let heightTotal = 0
    if (typeof option.dimension.height_additional !== 'undefined') {
      const heightPrice = Number.parseFloat(option.dimension.height_additional.price_per)
      const heightPer = Number.parseInt(option.dimension.height_additional.dim_per)
      const heightValue = Number.parseInt(option.dimension.height_additional.value)

      if (heightValue > 100) {
        const parts = heightValue - 100
        const cm = parts / heightPer
        const val = (cm + 1) * heightPrice
        heightTotal = val
      } else {
        heightTotal = heightPrice
      }
    }
    return heightTotal
  }

  updateDimensionAwningsPrices () {
    const step = this.configurator.steps.find(s => s.model_step)
    const option = step.options.find(o => o.selected)
    const markup = (step && typeof step.markup !== 'undefined') ? step.markup / 100 : 0

    if (option && typeof option.special_dim !== 'undefined') {
      // const standart = option.standart_dim.find(o => o.selected)
      const special = option.special_dim.find(s => s.selected)
      if (typeof special !== 'undefined') {
        const tax = 19 / 100
        const markupPercent = (option && option.markup) ? special.price * (Number.parseInt(option.markup) / 100) : special.price * markup
        const priceMarkup = special.price + markupPercent
        const priceTax = priceMarkup * tax
        const total = priceMarkup + priceTax
        // let price_discount = total * discounted;
        option.price.self = total
        option.price.total = step.visible ? option.price.self : 0
      }
    }
  }

  updateStandartDimPrice () {
    const step = this.configurator.steps.find(s => s.model_step)
    if (step) {
      const option = step.options.find(o => o.selected)
      const markup = (step && typeof step.markup !== 'undefined') ? step.markup / 100 : 0
      if (option && typeof option.standart_dim !== 'undefined') {
        const dims = option.standart_dim
        const tax = 19 / 100
        const heightTotal = this.countHeightTotal(option) // get height price
        const heightAdditionalTotal = this.countHeightAdditionalTotal(option) // get height additional price
        // let discounted = (option && typeof option.discount) ? option.discount / 100 : 0;
        // calc prices
        dims.forEach(dim => {
          if (dim && dim.price) {
            const markupPercent = (option && option.markup) ? dim.price * (Number.parseInt(option.markup) / 100) : dim.price * markup
            // calculate
            const priceMarkup = dim.price + markupPercent
            const priceTax = priceMarkup * tax
            const total = priceMarkup + priceTax + heightTotal + heightAdditionalTotal
            // let price_discount = total * discounted;
            // dim['discounted'] = total - price_discount;
            dim.total = total
          }
        })
      }
    }
  }

  updateStepPrice (step) {
    if (step.price && step.options) {
      if (['simpleselect', 'simpletechnic', 'conditional', 'montage_step', 'glass'].indexOf(step.type) !== -1) {
        step.price.options = step.options.reduce((price, option) => {
          return price + option.price.total
        }, 0)
        step.price.total = step.visible ? (step.price.self + step.price.options) : 0
      }

      if (step.type === 'color_roof' || step.type === 'color_screen') {
        const selectedColor = step.options.find(color => color.selected)
        if (selectedColor) {
          const totalRelatedPrice = this.configurator.steps.filter((filterStep) => step.price_on_steps.indexOf(filterStep.id) !== -1)
            .reduce((totalPrice, step) => totalPrice + step.price.total, 0)
          step.price.total = (totalRelatedPrice / 100) * selectedColor.price.total
        } else {
          step.price.total = 0
        }
      }
    }
  }

  updateOptionPrice (option) {
    if (option.price) {
      const quantityFactor = option.quantity_multiply ? this.configurator.quantity : 1
      if (option.type === 'count') {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor * option.value
      // sub options calc
      } else if (option && option.sub_options) {
        let subPrice = 0
        if (option && option.sub_options) {
          option.sub_options.filter(option => {
            if (option.selected) {
              subPrice += option.price
            }
          })
        }
        option.price.self = !option.selected || option.disabled ? 0 : subPrice * quantityFactor
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor
      } else {
        option.price.total = !option.selected || option.disabled ? 0 : option.price.self * quantityFactor
      }
    }
  }

  updateGlassOptionPrice (option, dim) {
    if (option.price) {
      const typeView = option.type_view
      if (typeView === 'L' || typeView === 'R') {
        const w = Number.parseInt(dim.width.value) / 100
        const h = Number.parseInt(dim.height.value) / 100
        // const l = Number.parseInt(dim.length.value) / 100
        const m2 = w * h // l * h
        // calc option total
        option.price.total = !option.selected || option.disabled ? 0 : m2 * option.price.self
      } else if (typeView === 'F' || typeView === 'B') {
        // const w = Number.parseInt(dim.width.value) / 100
        const h = Number.parseInt(dim.height.value) / 100
        const l = Number.parseInt(dim.length.value) / 100
        const m2 = l * h // w * h
        // calc option total
        option.price.total = !option.selected || option.disabled ? 0 : m2 * option.price.self
      } else if (typeView === 'K') { // Keil
        const l = Number.parseInt(dim.length.value) / 100
        // const price_option = l * option.price.total
        // calc option total
        option.price.total = !option.selected || option.disabled ? 0 : l * option.price.self
      }
    }
  }
}
