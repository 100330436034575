import { createApp } from 'vue'
import Accounting from 'accounting'
// import VueGtag from 'vue-gtag-next'
import App from './components/App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import Child from '~/components/Child'
import Loader from '~/components/Loader'
import Tooltip from '~/components/Tooltip'
import TooltipColor from '~/components/TooltipColor'
import './plugins'

const app = createApp(App);
// load components
[
  Child,
  Loader,
  Tooltip,
  TooltipColor
].forEach(Component => {
  app.component(Component.name, Component)
})
app.config.globalProperties.$filters = {
  image (image, parameter = null) {
    if (!image) {
      return require('@/assets/img/no-image.png')
    }

    if (!parameter) {
      return image.replace('image-service:', 'https://image-service.loewe-zaun.de')
    }

    const parts = image.split('/')
    parts.shift()
    const last = parts.pop()
    parts.push(parameter)
    parts.push(last)
    parts.unshift('https://image-service.loewe-zaun.de')
    return parts.join('/')
  },
  currency (value) {
    return Accounting.formatMoney(value, '€', 2, ',', '.')
  }
}
app.use(store)
  .use(router)
  .use(createMetaManager())
  .mount('#app')
