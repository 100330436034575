import * as types from '../mutation-types'
import axios from 'axios'
// state
export const state = {
  footer: null,
  statusFooter: false
}
// getters
export const getters = {
  footer: state => state.footer,
  statusFooter: state => state.statusFooter
}
// mutations
export const mutations = {
  [types.SET_FOOTER] (state, { footer }) {
    state.footer = footer
  },
  [types.SET_STATUS_FOOTER] (state, status) {
    state.statusFooter = status
  }
}
// actions
export const actions = {
  async setFooter ({ commit }) {
    const apiKey = btoa(process.env.VUE_APP_API_KEY)
    const apiUrl = process.env.VUE_APP_API_URL
    const { data } = await axios.post(apiUrl + 'foot', { re: apiKey })
    commit(types.SET_FOOTER, { footer: data })
  }
}
