import * as types from '../mutation-types'
// state
export const state = {
  referrerUrl: null,
  referrer: null
}
// getters
export const getters = {
  referrerUrl: state => state.referrerUrl,
  referrer: state => state.referrer
}
// mutations
export const mutations = {
  [types.SET_REFERRER_URL] (state, url) {
    state.referrerUrl = url
  },
  [types.SET_REFERRER] (state, val) {
    state.referrer = val
  }
}
// actions
export const actions = {
  setReferrerUrl ({ commit }, url) {
    if (url) {
      commit(types.SET_REFERRER_URL, null)
      commit(types.SET_REFERRER, null)
      const originUrl = new URL(url)
      // checking url
      if (originUrl.searchParams.has('fbclid')) {
        commit(types.SET_REFERRER_URL, url)
        commit(types.SET_REFERRER, 'facebook')
      } else if (originUrl.host === 'google.com') {
        commit(types.SET_REFERRER_URL, url)
        commit(types.SET_REFERRER, 'google')
      } else if (originUrl.host === 'bing.com') {
        commit(types.SET_REFERRER_URL, url)
        commit(types.SET_REFERRER, 'bing')
      } else {
        commit(types.SET_REFERRER_URL, url)
        commit(types.SET_REFERRER, originUrl.host)
      }
    }
  }
}
