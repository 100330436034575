export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.updateVisibility()
    this.updateSelection()
    if (this.configurator.steps.find((s) => s.type === 'dimension_glass')) {
      this.handleDimension()
    }
    if (this.configurator.steps.find((s) => s.type === 'dimension_awnings')) {
      this.handleDimensionAwnings()
    }

    return this
  }

  handleDimension () {
    const step = this.configurator.steps.find((s) => s.model_step)
    if (step && step.options) {
      const option = step.options.find((o) => o.selected)
      if (option && typeof option.special_dim !== 'undefined') {
        option.special_dim.find((s) => {
          if (s && s.selected) {
            s.selected = false
          }
        })

        // get dimension step values
        const currentDim = this.configurator.getActiveSteps().find((s) => s.type === 'dimension_glass')
        const w = Number.parseInt(currentDim.width.value)
        const l = Number.parseInt(currentDim.length.value)

        option.special_dim.forEach(s => {
          const lFrom = Number.parseInt(s.length.from)
          const lTo = Number.parseInt(s.length.to)
          const wFrom = Number.parseInt(s.width.from)
          const wTo = Number.parseInt(s.width.to)

          if (lTo <= 0) {
            if (lFrom <= l && lTo === 0 && wFrom <= w && wTo >= w) {
              s.selected = true
            }
          } else {
            if (lFrom <= l && lTo >= l && wFrom <= w && wTo >= w) {
              s.selected = true
            }
          }
        })
      }
    }
  }

  handleDimensionAwnings () {
    const step = this.configurator.steps.find((s) => s.model_step)
    if (step && step.options) {
      const option = step.options.find((o) => o.selected)
      if (option && typeof option.special_dim !== 'undefined') {
        option.special_dim.find((s) => {
          if (s && s.selected) {
            s.selected = false
          }
        })

        // get dimension step values
        const currentDim = this.configurator.getActiveSteps().find((s) => s.type === 'dimension_awnings')
        const w = Number.parseInt(currentDim.width.value)
        const l = Number.parseInt(currentDim.length.value)

        option.special_dim.forEach(s => {
          const lFrom = Number.parseInt(s.length.from)
          const lTo = Number.parseInt(s.length.to)
          const wFrom = Number.parseInt(s.width.from)
          const wTo = Number.parseInt(s.width.to)

          if (lTo <= 0) {
            if (lFrom <= l && lTo === 0 && wFrom <= w && wTo >= w) {
              s.selected = true
            }
          } else {
            if (lFrom <= l && lTo >= l && wFrom <= w && wTo >= w) {
              s.selected = true
            }
          }
        })
      }
    }
  }

  selectSpecial (option, w, l) {
    option.special_dim.filter((s) => {
      const lFrom = Number.parseInt(s.length.from)
      const lTo = Number.parseInt(s.length.to)
      const wFrom = Number.parseInt(s.width.from)
      const wTo = Number.parseInt(s.width.to)
      // find special values and set selected
      if (lFrom <= l && lTo >= l && wFrom <= w && wTo >= w) {
        s.selected = true
      }
    })
  }

  updateVisibility () {
    this.configurator.steps.forEach((step) => {
      this.processRestrictions(step)

      if (step.options) {
        step.options.forEach((option) => {
          this.processRestrictions(option)
        })
      }
      if (step.colors) {
        step.colors.forEach((color) => {
          this.processRestrictions(color)
        })
      }
    })
  }

  prepareRestrictions (obj) {
    const gen = function * entries (obj) {
      for (const key of Object.keys(obj)) {
        yield [key, obj[key]]
      }
    }

    const restrictions = {
      only: [],
      not: []
    }

    if (obj.only_for && obj.only_for.selection) {
      for (const [stepId, options] of gen(obj.only_for.selection)) {
        options.forEach((optionId) => {
          restrictions.only.push({ stepId, optionId })
        })
      }
    }

    if (obj.not_for && obj.not_for.selection) {
      for (const [stepId, options] of gen(obj.not_for.selection)) {
        options.forEach((optionId) => {
          restrictions.not.push({ stepId, optionId })
        })
      }
    }

    return restrictions
  }

  processRestrictions (object) {
    let visible = true
    const restrictions = this.prepareRestrictions(object)

    if (restrictions.only && restrictions.only.length > 0) {
      visible = !!restrictions.only.find(({ stepId, optionId }) => {
        const option = this.configurator.getOption({ stepId, optionId })

        return option && option.selected
      })
    }

    if (visible && restrictions.not && restrictions.not.length > 0) {
      visible = !restrictions.not.find(({ stepId, optionId }) => {
        const option = this.configurator.getOption({ stepId, optionId })
        return option && option.selected
      })
    }

    object.visible = visible
  }

  updateSelection () {
    this.configurator.steps.forEach((step) => {
      if (step.options) {
        step.options.forEach((option) => {
          if (!option.visible) {
            option.selected = false
          }
        })
      }
    })
  }
}
