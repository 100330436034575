import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
const router = createRoutes()
export default router
function createRoutes () {
  const router = createRouter({
    scrollBehavior,
    history: createWebHistory(),
    routes
  })
  return router
}
/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return { selector: to.hash }
  }
  /*
  const [component] = router.getMatchedComponents({ ...to }).slice(-1)

  if (component && component.scrollToTop === false) {
    return {}
  }
  */
  return { x: 0, y: 0 }
}
