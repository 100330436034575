/* eslint-disable no-cond-assign */
/* eslint-disable no-case-declarations */
import axios from 'axios'
export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  async process () {
    /*
    let colorStep
    if (colorStep = (this.configurator.steps || []).find(s => s.type === 'color')) {
      const color = colorStep.colors.find(c => c.selected)

      const result = this.getImageFromServer(this.configurator.id, color.id)
      return result
    }
    */
    return null
  }

  async getImageFromServer (id, colorId) {
    const { data } = await axios.get('/image-manager/' + [id, colorId].join('/'))
    return data
  }
}
