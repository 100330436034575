export default class {
  constructor ({ state, getters }) {
    this.state = state
    this.getters = getters
  }

  createDelivery () {
    if (this.state.pickup) {
      return {
        type: 'pickingup',
        selected: true
      }
    } else {
      return {
        type: 'sending',
        selected: true,
        countries: [
          { ...this.getters.country, selected: true }
        ]
      }
    }
  }

  createPaymentAmount () {
    return {
      selected: true,
      ...this.getters.paymentAmountType
    }
  }

  createPayment () {
    return {
      selected: true,
      name: this.getters.paymentType.label
    }
  }

  createItems () {
    return this.getters.items
  }

  createGuarantee () {
    return {
      name: 'Bürgschaft',
      selected: this.state.payment.guarantee
    }
  }

  createPersonal () {
    const address = this.state.customer.address
    const deliveryAddress = this.state.customer.deliveryAddress

    return {
      primary: {
        ...address,
        tel: address.phone,
        zipcode: address.zip,
        additional: this.state.customer.additional
      },
      secondary: {
        ...deliveryAddress,
        tel: deliveryAddress.phone,
        zipcode: deliveryAddress.zip
      },
      use_secondary: this.state.customer.differentDeliveryAddress
    }
  }

  serialize () {
    return btoa(JSON.stringify({
      order_number: null,
      delivery: [this.createDelivery()],
      payment_conditions: [this.createPaymentAmount()],
      payment_methods: [this.createPayment()],
      items: this.createItems(),
      guarantee: this.createGuarantee(),
      personal: this.createPersonal(),
      tax: 19,
      re: btoa(process.env.VUE_APP_API_KEY),
      montageBonus: this.state.montageBonus,
      promocode: this.state.promocode,
      multiple: this.state.multiple
    }))
  }
}
